/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import styles from './index.module.scss';
import { Button, SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { ServicePlanCards, ServicePlanData, ContentCta } from '../../store';
import classnames from 'classnames';
import { ServicePlanCardPopupContext } from '../../index';

interface CardProps {
  cards: ServicePlanCards;
}

const createCard = (
  heading: string,
  tooltip: string | undefined,
  text: string[],
  rrp: ServicePlanData | undefined,
  cta: ContentCta | undefined,
  servicePlanTable: ServicePlanData[],
  shadowEffect: boolean,
  onMoreInfoClick: (item: ServicePlanData[]) => void
) => {
  const isMostPopularCard = heading.includes('5 year');

  const cardStyles = {
    boxShadow: isMostPopularCard
      ? shadowEffect
        ? 'inset 0px -10px 10px -10px rgb(202 203 204 / 59%)'
        : 'none'
      : 'none',
  };

  return (
    <div
      className={classnames(styles.card, {
        [styles.mostPopularCard]: isMostPopularCard,
      })}
      style={cardStyles}
    >
      {heading.includes('5 year') ? (
        <div className={styles.mostPopular}>MOST POPULAR</div>
      ) : null}
      <div className={styles.cardHeading}>
        <h6 className={styles.title}>{heading}</h6>
        {tooltip && (
          <TooltipDisclaimer
            className={styles.tooltipDisclaimer}
            tooltipClassName={styles.displayText}
            followCursor
            disclaimer={tooltip as string}
          />
        )}
      </div>
      <div className={styles.cardContents}>
        {text.map((item, index) => (
          <div className={styles.cardContent} key={index}>
            <div
              className={styles.icon}
              style={{
                boxShadow: `0px 0px 1px 1px "#FFFFFF"`,
              }}
            >
              <SvgIcon type="check" size={0.5} color="#FFFFFF" />
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: item.trim() as string,
              }}
            />
          </div>
        ))}
      </div>
      <div className={styles.buttonFooter}>
        {rrp && <div className={styles.pricing}>RRP: ${rrp.price}</div>}
        <div className={styles.cardButton}>
          {rrp && (
            <Button
              block
              buttonSize="small"
              type={cta?.ctaType}
              onClick={() => onMoreInfoClick(servicePlanTable)}
            >
              {cta?.ctaLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const ServicePlanCard: React.FC<CardProps> = ({ cards }) => {
  const { setPopupTitle, setPopupSelectedItem, isPopupOpen, setIsPopupOpen } =
    useContext(ServicePlanCardPopupContext);
  const {
    fiveYearHeading,
    fiveYearTooltip,
    fiveYearText,
    fiveYearCta,
    threeYearHeading,
    threeYearText,
    threeYearTooltip,
    threeYearCta,
    servicePlanData,
    fiveYearShadowEffect,
  } = cards[0];

  const threeYearTable = servicePlanData[0] as ServicePlanData[];
  const fiveYearTable = servicePlanData[1] as ServicePlanData[];

  const threeYearRRP = servicePlanData[0]?.find(
    (item) => item.description === 'RRP'
  );
  const fiveYearRRP = servicePlanData[1]?.find(
    (item) => item.description === 'RRP'
  );

  const onMoreInfoClick = (heading: string, item: ServicePlanData[]) => {
    setPopupSelectedItem(item);
    setPopupTitle(heading);
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className={styles.spCardContainer}>
      {createCard(
        threeYearHeading,
        threeYearTooltip,
        threeYearText,
        threeYearRRP,
        threeYearCta,
        threeYearTable,
        fiveYearShadowEffect,
        () => onMoreInfoClick(threeYearHeading, threeYearTable)
      )}
      {createCard(
        fiveYearHeading,
        fiveYearTooltip,
        fiveYearText,
        fiveYearRRP,
        fiveYearCta,
        fiveYearTable,
        fiveYearShadowEffect,
        () => onMoreInfoClick(fiveYearHeading, fiveYearTable)
      )}
    </div>
  );
};

export default ServicePlanCard;
