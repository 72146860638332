/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { ServicePlanData } from '../../store';
import { SvgIcon } from '@ui-elements';

interface ServicePopupProps {
  title: string;
  selectedItem: ServicePlanData[] | undefined;
  isPopupOpen: boolean;
  onClosePopup?: () => void;
}

const ServicePopup: React.FC<ServicePopupProps> = ({
  title,
  selectedItem,
  isPopupOpen,
  onClosePopup,
}) => {
  const [popUpContainer, setPopUpContainer] = useState([
    styles.servicePopupContainer,
  ]);

  const value = selectedItem
    ?.find((item) => item.description === 'RRP')
    ?.price?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });

  const serviceInternals = selectedItem?.filter(
    (item) => item.description !== 'RRP'
  );

  const handleClosePopup = () => {
    closePopup(false);
    if (onClosePopup) {
      onClosePopup();
    }
  };

  const closePopup = (popupOpen) => {
    setPopUpContainer([
      styles.servicePopupContainer,
      ...(popupOpen ? [styles.show] : []),
    ]);
  };

  useEffect(() => {
    closePopup(isPopupOpen);
  }, [isPopupOpen]);

  return (
    <div className={popUpContainer.join(' ')}>
      <div className={styles.servicePopupWrapper}>
        <div className={styles.closeButton} onClick={handleClosePopup}>
          <SvgIcon type="close" color="#3a3b3b" size="30px" />
        </div>
        <h6 className={styles.servicePopupTitle}>{title}</h6>
        <table className={styles.servicePlanTable}>
          <thead>
            <tr>
              <th>Scheduled Service Internals</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {serviceInternals?.map((item, index) => (
              <tr key={index}>
                <td>{item.description}</td>
                <td>${item.price}</td>
              </tr>
            ))}
            <tr>
              <td />
              <td />
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>TOTAL</td>
              <td>{value}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default ServicePopup;
